<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
    transition="dialog-bottom-transition"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Printing details</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-575px scroll-y mx-5 mx-xl-15 py-7"
      >
        <!--begin::Body-->
        <div :class="['row my-5 justify-content-center']">
          <div
            v-for="(print, index) in types"
            :key="index"
            @click="() => onPrint(print.value)"
            :class="[
              { 'col-4 col-md-3': print.show },
              { 'd-none': !print.show },
            ]"
          >
            <button v-if="print.show" class="btn btn-block btn--export-filter">
              {{ print.name }}
            </button>
          </div>
        </div>
        <hr class="my-10" />
        <div class="d-flex justify-content-center">
          <button @click="toggleModal" class="btn btn--export-filter">
            Done
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  SET_REPORT_MODAL_STATE,
  SET_REPORT_MODAL_DATA,
} from "@/core/services/store/config.module";
import { UPDATE_TOTE_DATA } from "@/core/services/store/packing2.module";
// import Swal from "sweetalert2";

export default {
  name: "InvoiceModal",
  props: ["refresher"],
  data: () => ({
    dialog: false,
    // types: [
    //   { name: "Shipping label", value: "label", show: this.successStatus },
    //   { name: "Barcode", value: "barcode", show: false },
    //   { name: "Invoice", value: "invoice" },
    //   { name: "Sales Invoice", value: "sales_invoice" },
    //   { name: "Order Invoice", value: "invoice" },
    //   { name: "Packing summary", value: "packing_summary" },
    //   { name: "Manifest", value: "manifest" },
    //   { name: "Packing slip", value: "packing_slip" },
    // ],
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        // this.refresher();
        const payload = {
          warehouse_id: this.selectedWarehouse,
          tote_id: this.$store.getters.getWMSPACKINGSelectedTote,
        };
        this.$emit("on-close-print");
        if (this.pickingType === 1) {
          this.$store.dispatch(UPDATE_TOTE_DATA, payload);
        }
      }
      this.dialog = !this.dialog;
    },
    // showReport(type, items) {
    //   let total = items.length;
    //   let available = items.filter(
    //     (item) => item.id.detail.print[`${type}`] === true
    //   );
    //   let notAvailable = items.filter(
    //     (item) => item.id.detail.print[`${type}`] === false
    //   );
    //   let typeAmountAvailable = available.length;
    //   if (typeAmountAvailable !== total) {
    //     let message = notAvailable.map((item) => `<li>${item.id.id}</li>`);

    //     Swal.fire({
    //       title: "Warning",
    //       html: `<div>
    //                 <div>The ${typeAmountAvailable} of ${total} available for ${type}</div>
    //                 <div>
    //                   <span>Orders that are not printed:</span>
    //                   <ul class="text-left pl-6">${message.join("")}</ul>
    //                 </div>
    //               </div>`,
    //       icon: "warning",
    //       showConfirmButton: true,
    //     });
    //   }
    //   return {
    //     total: total,
    //     availableAmount: typeAmountAvailable,
    //     available: available,
    //     notAvailable: notAvailable,
    //   };
    // },
    onPrint(val) {
      // if (items.length > 0) {
      // let isRepotShown = this.showReport(val, items);
      // if (isRepotShown.availableAmount > 0) {
      this.$store.commit(SET_PAGE_LOADING, true);
      // let itemSystemCodes = items.map((item) => item.systemId);
      let data = {
        type: val,
        orders: [this.orderDetails.order_client_reference],
      };
      ApiService.post("/orders/print", data)
        .then((response) => {
          this.$store.commit(SET_REPORT_MODAL_DATA, {
            total_orders_count: response.data.total_orders_count,
            successful_orders_count: response.data.successful_orders_count,
            failed_orders_count: response.data.failed_orders_count,
            successful_orders: response.data.successful_orders,
            failed_orders: response.data.failed_orders,
            message: response.data.message,
          });
          this.$store.commit(SET_REPORT_MODAL_STATE, true);
          if (response.data.successful_orders_count > 0) {
            if (response.data.path) {
              response.data.path.forEach((element) => {
                const link = document.createElement("a");
                link.href = element;
                link.target = "_blank";
                link.download = "orders.csv";
                link.click();
              });
            }

            // setTimeout(() => {
            // }, 1000);
          }
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      // }
      //  else {
      //   Swal.fire({
      //     title: "Warning",
      //     text: `${name} is not ready for selected orders.`,
      //     icon: "warning",
      //     showConfirmButton: false,
      //   });
      // }
      // } else {
      //   Swal.fire({
      //     title: "Warning",
      //     text: "Please, select at least one order!",
      //     icon: "warning",
      //     showConfirmButton: false,
      //   });
      // }
    },
  },
  computed: {
    types: function () {
      return [
        {
          name: "Shipping label",
          value: "label",
          show: true,
        },
        {
          name: "Barcode",
          value: "barcode",
          show: true,
        },
        // { name: "Invoice", value: "order_invoice", show: true },
        {
          name: "Sales Invoice",
          value: "sales_invoice",
          show: true,
        },
        {
          name: "Order Invoice",
          value: "order_invoice",
          show: true,
        },
        {
          name: "Packing summary",
          value: "packing_summary",
          show: true,
        },
        {
          name: "Manifest",
          value: "manifest",
          show: true,
        },
        {
          name: "Packing slip",
          value: "packing_slip",
          show: true,
        },
        {
          name: "Delivery Note",
          value: "delivery_note",
          show: true,
        },
      ];
    },
    orderDetails: function () {
      return this.$store.getters.getWMSPACKINGOrderDetails;
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    pickingType: function () {
      return this.$store.getters.getWMSPACKINGPickType;
    },
  },
};
</script>
